@import url("https://fonts.googleapis.com/css2?family=Cookie&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Ubuntu;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

body {
  background-color: #d0eaff;
  background-image: linear-gradient(
    20deg,
    #d0eaff 0%,
    #edf5fb 25%,
    #ffffff 50%,
    #ecf3f9 75%,
    #f8e1f8 100%
  );
  background-attachment: fixed;
  /* background-image: url("images/background.jpeg"); */
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.dark {
  background-image: linear-gradient(
    20deg,
    #000000 0%,
    #000000 25%,
    #000000 50%,
    #000000 75%,
    #000000 100%
  );
}

.h-content {
  padding-top: 72px;
  width: 100%;
  padding-bottom: 20px;
  min-height: calc(100vh - 60px);
}

::-webkit-scrollbar {
  display: none;
}
